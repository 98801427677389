<template>
  <div class="church-profile template-3 manageromo-section">
    <header class="page-header container">
      <h1>{{ translations.tcManageBlitzes }}</h1>
    </header>
    <div id="card" class="container">
      <div class="list-area">
        <data-table
          :title="translations.tcUSAScripture"
          :tooltip="translations.tcUSAScripture"
          :fields="dataTableFields"
          :items="usa_blitzes"
          :addItems="addItems"
          :sortBy="sortBy"
          @addEvent="addBlitz"
          @editEvent="editBlitz"
          @deleteEvent="deleteBlitz"
          :i18n="translations.components"
        ></data-table>
        <!-- / Data Tables -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import DataTable from '@/components/DataTableConventions.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { translationMixin } from '@/mixins/translationMixin'


export default {
  name: 'manage-blitzes',
  mixins: [translationMixin, calendarMixin],
  data () {
    return {
      sortBy: 'blz_name',
      translations: {},
      dataTableFields: [],
      addItems: {},
      view_calendar_event_controls: {
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166',
      },
    }
  },
  async created() {
    if (this.iCanSee(this.view_calendar_event_controls.calendar_add_event_button)) {
      this.addItems = {
        display: true,
        text: 'Add Blitz',
        action: 'addEvent',
      }
    }
    await this.page_load()
  },
  methods: {
    ...mapActions({
      loadBlitzes: 'usaScriptureBlitz/loadAllBlitzes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedUSABlitzKey: 'user/setSelectedUSABlitzKey',
      deleteBlitzInfo: 'usaScriptureBlitz/deleteBlitz'
    }),
    async page_load() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('data-table-conventions')
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.loadBlitzes()
        this.setLoadingStatus(false)

      })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddBlitz
      this.loadDataTableFields()
    },
    loadDataTableFields() {
      this.dataTableFields = [
        {
          key: 'blz_start_date',
          label: `${this.translations.tcBlitzDate}`,
          formatter: (value, key, item) => {
            return this.formatForDatePicker(value)
          },
          sortable: true },
        { key: 'blz_name', label: `${this.translations.tcBlitzName}`, sortable: true },
        { key: 'blz_key', label: `${this.translations.tcEditDelete}`, sortable: false },
      ]
    },
    async addBlitz() {
      this.setSelectedUSABlitzKey(''),
      this.$router.push({ name: 'add-blitz' })
    },
    async deleteBlitz(data) {
      let response = null
      const blitz = {
        blz_key: data,
        user_key: this.userId,
        user: null,
        delete_flag: true,
      }
      await Promise.all([
        this.setLoadingStatus(true),
        (response = await this.deleteBlitzInfo(blitz)),
        await this.loadBlitzes(),
        this.setLoadingStatus(false),
      ]).then(() => {
        if (!response) {
          this.$swal({
            icon: 'error',
            confirmButtonText: this.translations.tcOk,
            text: this.translations.tcErrorUpdatingBlitzDelete,
          }).then((result) => {
            return
          })
        }
      })
    },
    async editBlitz(data) {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.setSelectedUSABlitzKey(data),
        this.setLoadingStatus(false),
      ]).then(() => {
        this.$router.push({ name: 'add-blitz' })
      })
    },
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      usa_blitzes: 'usaScriptureBlitz/usa_blitzes',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
  },
  components: {
    dataTable: DataTable,
    iTooltip: iTooltip,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/views/ChurchProfile.scss';
@import '@/styles/views/ManagePromo.scss';

</style>
